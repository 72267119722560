import { AxiosError, AxiosResponse } from 'axios';
import { PostBodyLoginInterface, ResponseLoginInterface } from 'interface/UserInterface';

import { axiosLoginServiceInstance } from './axios.loginServiceConfig';

export interface ValidationLoginInterface {
  email?: string[];
  password?: string[];
}
export interface CustomResponseLoginInterface extends Omit<ResponseLoginInterface, 'message'> {
  message?: string | ValidationLoginInterface | null;
}

export const postUserLogin = async (
  body: PostBodyLoginInterface
): Promise<CustomResponseLoginInterface> => {
  return axiosLoginServiceInstance
    .post('/web/loginByEmail', body)
    .then((res: AxiosResponse<CustomResponseLoginInterface>) => res.data)
    .catch((error: AxiosError) => error?.response?.data);
};
